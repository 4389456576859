import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const postDistribute = async (endpoint: string, id: string, body: {
    createCopy: boolean,
    userIds: Array<string>,
}): Promise<{ status: number }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'POST Distribute',
        };

        const requestInit = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${(typeof chaynsInfo !== 'undefined' && chaynsInfo.User?.TobitAccessToken) || (typeof chayns !== 'undefined' && chayns.env.user.tobitAccessToken)}`,
                'Content-Type': 'application/json; charset=utf-8',
            },
            body: JSON.stringify(body),
        };

        const response = await logFetch(`${endpoint}v1.0/me/Mails/${id}/Distribute`, requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to post resubmit',
            section: 'api/smart-client/post/postResubmit.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { postDistribute };
