import logFetch from '../../../utils/logFetch';
import logger from '../../../constants/logger';
import { INTERCOM_API_URL } from '../../../constants/defaults';

const patchLeaveGroup = async (threadId: string, memberId: string): Promise<{ status: number }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'PATCH leave group',
        };

        const requestInit = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
        };

        const response = await logFetch(`${INTERCOM_API_URL}thread/${threadId}/member/${memberId}`, requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to patch leave group',
            section: 'api/intercom/patch/patchLeaveGroup.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { patchLeaveGroup };
