import { createAction } from '@reduxjs/toolkit';
import { CLEAR_SEARCH, SET_IS_SEARCHING_PAGES, SET_IS_SEARCHING_PERSONS, SET_IS_SEARCHING_SITES, SET_PAGES_ACTIVE_PAGE, SET_PAGES_PAGE, SET_PERSONS_ACTIVE_PAGE, SET_PERSONS_PAGE, SET_SEARCH_PAGES, SET_SEARCH_PERSONS, SET_SEARCH_SITES, SET_SEARCH_STRING, SET_SITES_ACTIVE_PAGE, SET_SITES_PAGE } from './types';
import { Site } from '../../types/chayns';
import { Person } from '../../types/person';
import { Page } from '../../types/Page';

export type SearchResults<T> = {
    activePage?: number,
    total?: number,
    values?: Array<T>,
    list?: Array<T>,
    count?: number,
};

export type Search = {
    searchString: string,
    sitesSearch: SearchResults<Location>,
    pagesSearch: SearchResults<Page>,
    personsSearch: SearchResults<Person>,
    isSearchingPages: boolean,
    isSearchingPersons: boolean,
    isSearchingSites: boolean,
};

export const setSearchString = createAction<string>(SET_SEARCH_STRING);

export const setIsSearchingSites = createAction<boolean>(SET_IS_SEARCHING_SITES);
export const setSearchSites = createAction<{ value: { total?: number, values: Array<Site> }}>(SET_SEARCH_SITES);
export const setSitesPageResult = createAction<{ value: { startIndex: number, values: Array<Location> } }>(SET_SITES_PAGE);
export const setSitesActivePage = createAction<number>(SET_SITES_ACTIVE_PAGE);

export const setIsSearchingPages = createAction<boolean>(SET_IS_SEARCHING_PAGES);
export const setSearchPages = createAction<{ value: {values: Array<Site>, total?: number }}>(SET_SEARCH_PAGES);
export const setPagesPageResult = createAction<{ value: { startIndex: number, values: Array<Page> } }>(SET_PAGES_PAGE);
export const setPagesActivePage = createAction<number>(SET_PAGES_ACTIVE_PAGE);

export const setIsSearchingPersons = createAction<boolean>(SET_IS_SEARCHING_PERSONS);
export const setSearchPersons = createAction<{ value: { list: Array<Person>, count?: number }}>(SET_SEARCH_PERSONS);
export const setPersonsPageResult = createAction<{ value: { startIndex: number, values: Array<Person> } }>(SET_PERSONS_PAGE);
export const setPersonsActivePage = createAction<number>(SET_PERSONS_ACTIVE_PAGE);

export const setClearSearch = createAction(CLEAR_SEARCH);
