import { createReducer } from '@reduxjs/toolkit';
import { setTextStrings, TextStrings } from './actions';

const initialState = {} as TextStrings;

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setTextStrings, (draft, action) => action.payload);
});

export default reducer;
