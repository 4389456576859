import { createReducer } from '@reduxjs/toolkit';
import getIsThreadRead from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/getIsThreadRead';
import { sortMembers, sortMessages, sortThreads } from '../../components/tapp/tapp-content/sites-tapp/messages/intercom-item/utils/sort';
import { INTERCOM } from '../../constants/types';
import { Member, Thread } from '../../types/thread';
import { mergeArrays } from '../../utils/mergeThreads';
import { resetState } from '../app/actions';
import { handleLoadIntercom, Intercom, setDeletedIntercomThreads, setIntercomLoadingError, setIntercomPlugins, setIntercomThreads, setReadIntercomThread, setUpdatedIntercomThread } from './actions';

const initialState : Intercom = {
    fetchedData: false,
    lastFetch: null,
    threads: null,
    plugins: [],
};

const setIntercom = (draft, action) => {
    const {
        result,
        updateLastFetch,
        selectedItem,
    } = action.payload;

    draft.fetchedData = true;
    draft.threads = mergeArrays(draft.threads, result);

    draft.threads = sortThreads(draft.threads);

    const updatedThreadIds = result.threads?.map((updatedThread: Thread) => updatedThread.id);

    draft.threads.forEach((thread: Thread, i: number) => {
        if (updatedThreadIds?.includes(thread.id)) {
            draft.threads[i].members = sortMembers(thread.members);
            draft.threads[i].messages = thread.messages ? sortMessages(thread.messages) : undefined;
        }
    });

    // Remove read threads
    const newThreads = draft.threads.map((thread: Thread) => {
        const isThreadRead = getIsThreadRead(thread);
        const isThreadSelected = selectedItem?.type === INTERCOM && selectedItem.id === thread.id;
        if (isThreadRead && !isThreadSelected) {
            return null;
        }

        return thread;
    }).filter((t: Thread) => t);

    draft.threads = newThreads;

    if (updateLastFetch) {
        draft.lastFetch = new Date().toISOString();
    }
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(handleLoadIntercom.fulfilled, (draft, action) => {
        setIntercom(draft, action);
    });

    builder.addCase(handleLoadIntercom.rejected, (draft) => {
        draft.fetchedData = true;
    });

    builder.addCase(setIntercomLoadingError, (draft) => {
        draft.fetchedData = true;
    });

    builder.addCase(setIntercomThreads, (draft, action) => {
        setIntercom(draft, action);
    });

    builder.addCase(setReadIntercomThread, (draft, action) => {
        const {
            id,
            selectedItem,
        } = action.payload;

        if (selectedItem?.type !== INTERCOM || selectedItem.id !== id) {
            draft.threads = draft.threads.filter((thread: Thread) => thread.id !== id);
        } else {
            const threadIndex = draft.threads.findIndex((t: Thread) => t.id === id);
            if (threadIndex > -1) {
                const thread = draft.threads[threadIndex];
                const userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo.User.ID : window?.chayns?.env.user.id;
                const updatedThread = {
                    ...thread,
                    members: thread.members.map((member: Member) => {
                        if (member.tobitId === userId) {
                            return {
                                ...member,
                                unreadFlag: false,
                                unreadCount: 0,
                            };
                        }
                        return member;
                    }),
                };
                draft.threads[threadIndex] = updatedThread;
            }
        }
    });

    builder.addCase(setUpdatedIntercomThread, (draft, action) => {
        const {
            thread,
            selectedItem,
        } = action.payload;

        const isThreadRead = getIsThreadRead(thread);
        const isThreadSelected = selectedItem?.type === INTERCOM && selectedItem.id === thread.id;

        if (isThreadRead && !isThreadSelected) {
            draft.threads = draft.threads.filter((t: Thread) => thread.id !== t.id);
        } else {
            const threadIndex = draft.threads?.findIndex((t: Thread) => t.id === thread.id);
            if (threadIndex > -1) {
                draft.threads[threadIndex] = thread;
            }
        }
    });

    builder.addCase(setDeletedIntercomThreads, (draft, action) => {
        const {
            threadIds,
        } = action.payload;

        if (draft.threads) {
            draft.threads = draft.threads.filter((thread: Thread) => !threadIds.includes(thread.id));
        }
    });

    builder.addCase(setIntercomPlugins, (draft, action) => {
        draft.plugins = action.payload;
    });

    builder.addCase(resetState, () => initialState);
});

export default reducer;
