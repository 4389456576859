import { MIN_CALENDAR_API_VERSION } from '../../constants/defaults';
import { Mail, SmartClientServer } from '../../types/smartClient';
import { RootState } from '../rootReducer';

export const getSmartClientFetchedData = (state : RootState) : boolean => state.smartClient.fetchedData;
export const getSmartClientServerState = (state : RootState) : Array<SmartClientServer> => state.smartClient.server;

export const getActiveServerState = (state: RootState) : SmartClientServer => {
    const { app, smartClient } = state;
    const { activeDavidSiteId } = app;
    return smartClient?.server?.find((server) => server.davidSiteId === activeDavidSiteId);
};

export const getMailsState = (state : RootState) : Array<Mail> => {
    const activeServer = getActiveServerState(state);
    const { messageSearchString } = state.app;
    if (messageSearchString) {
        return activeServer?.searchMails?.values || [];
    }
    if (activeServer?.emailConnection?.values) {
        return activeServer.emailConnection.values || [];
    }
    return [];
};

export const getSelectedMailState = (state : RootState) : Mail => {
    const { app } = state;
    const { selectedItem } = app;
    if (!selectedItem) {
        return null;
    }

    const mails = getMailsState(state);
    return mails?.find((mail) => mail.id === selectedItem.id);
};
export const getMailByIdState = (state : RootState, id : string) : Mail => {
    const mails = getMailsState(state);
    return mails?.find((mail) => mail.id === id);
};

export const getIsCalendarShown = (state: RootState): boolean => {
    const server = getActiveServerState(state);
    return server?.apiVersion > MIN_CALENDAR_API_VERSION;
};
