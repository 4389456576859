import { createAction } from '@reduxjs/toolkit';

import { SET_CONTEXT_MENU } from './types';

export type ContextMenu = {
    id?: string,
    isList?: boolean,
    contextType?: string,
    x?: number,
    y?: number,
};

export const setContextMenu = createAction<ContextMenu>(SET_CONTEXT_MENU);
