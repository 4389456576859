import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { loadSites } from '../../api/sites/get/index';
import { deleteSite } from '../../api/sites/delete/deleteSite';

import { Location } from '../../types/location';

import { SET_DRAGGED_SITE, SET_GLOBAL_LOCATIONS, SET_IGNORE_FOCUSED, SET_IS_LOADING_SITES, SET_SITES } from './types';

export type Sites = {
    count: number,
    total: number,
    values: Array<Location>,
};

export type SitesObject = {
    sites?: Sites,
    isLoading?: boolean,
    draggedSite: number | null,
    globalLocations: Array<Location>,
    ignoreFocused?: boolean,
};

export const setSites = createAction<Sites>(SET_SITES);
export const setDraggedSite = createAction<number>(SET_DRAGGED_SITE);
export const setGlobalLocations = createAction<Array<Location>>(SET_GLOBAL_LOCATIONS);
export const setIgnoreFocused = createAction<boolean>(SET_IGNORE_FOCUSED);
export const setIsLoadingSites = createAction<boolean>(SET_IS_LOADING_SITES);

export const handleLoadSites = createAsyncThunk(
    'sites/handleLoadSites',
    async ({ isMyChaynsApp } : { isMyChaynsApp: boolean }, thunkApi) => {
        if ((typeof chaynsInfo !== 'undefined' && window.chaynsInfo.User?.TobitAccessToken) || (typeof chayns !== 'undefined' && chayns.env.user.tobitAccessToken)) {
            const { status, result } = await loadSites(isMyChaynsApp);

            if (status === 200 && result) {
                const sliceLocations = {
                    ...result,
                    values: result.values,
                };
                thunkApi.dispatch(setSites(sliceLocations));
                return sliceLocations;
            }
        }
        return [];
    },
);

export const handleDeleteSite = createAsyncThunk(
    'sites/handleDeleteSite',
    async ({ isMyChaynsApp, sites, id } : { isMyChaynsApp: boolean, sites: Array<Location>, id: number }, thunkApi) : Promise<boolean> => {
        const { status } = await deleteSite(id);
        if (status < 300) {
            if (sites?.length === 0) {
                return true;
            }
            thunkApi.dispatch(handleLoadSites({ isMyChaynsApp }));
            return true;
        }
        thunkApi.dispatch(handleLoadSites({ isMyChaynsApp }));
        return false;
    },
);
