import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { SitesObject, setSites, setDraggedSite, setGlobalLocations, setIgnoreFocused, setIsLoadingSites } from './actions';

const initialState = {} as SitesObject;

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setSites, (draft: SitesObject, action) => {
        draft.sites = action.payload;
    });

    builder.addCase(setDraggedSite, (draft: SitesObject, action) => {
        draft.draggedSite = action.payload;
    });

    builder.addCase(setGlobalLocations, (draft: SitesObject, action) => {
        draft.globalLocations = action.payload;
    });

    builder.addCase(setIgnoreFocused, (draft: SitesObject, action) => {
        draft.ignoreFocused = action.payload;
    });

    builder.addCase(setIsLoadingSites, (draft, action) => {
        draft.isLoading = action.payload;
    });

    builder.addCase(resetState, (draft) => ({
        ...draft,
        sites: null,
        isLoading: false,
        draggedSite: null,
        ignoreFocused: false,
    }));
});

export default reducer;
