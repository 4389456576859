import { Mail } from '../../types/smartClient';

const mergeMails = (oldMails: Array<Mail>, newMails: Array<Mail>): Array<Mail> => {
    let mergedMails = [];
    if (!oldMails) {
        mergedMails = newMails;
    } else {
        newMails.forEach((newMail) => {
            const findMail = oldMails.find((oldMail) => oldMail.id === newMail.id);
            if (findMail) {
                const updatedMail = {
                    ...newMail,
                    body: findMail.body || newMail.body,
                    allowComments: findMail.allowComments || newMail.allowComments,
                    toAddresses: findMail.toAddresses || newMail.toAddresses,
                    ccAddresses: findMail.ccAddresses || newMail.ccAddresses,
                    attachments: findMail.attachments || newMail.attachments,
                    comments: findMail.comments || newMail.comments,
                    fetchedData: findMail.fetchedData || newMail.fetchedData,
                    hasAttachments: findMail.hasAttachments || newMail.hasAttachments,
                    type: findMail.type || newMail.type,
                    createdDateTime: newMail.createdDateTime || findMail.createdDateTime,
                    answerCommentId: newMail.answerCommentId || findMail.answerCommentId,
                };
                mergedMails.push(updatedMail);
            } else {
                mergedMails.push(newMail);
            }
        });
        oldMails.forEach((oldMail) => {
            if (!mergedMails.find((mail) => mail.id === oldMail.id)) {
                mergedMails.push(oldMail);
            }
        });
    }
    mergedMails.sort((a, b) => b.createdDateTime?.localeCompare(a.createdDateTime));
    return mergedMails;
};

export default mergeMails;
