import { createReducer } from '@reduxjs/toolkit';
import { Env, setAppVersion, setEnv } from './actions';

const initialState : Env = {};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setEnv, (draft, action) => action.payload);

    builder.addCase(setAppVersion, (draft, action) => {
        draft.appVersion = action.payload;
    });
});

export default reducer;
