import { createAsyncThunk } from '@reduxjs/toolkit';
import { Location } from '../../types/location';
import { loadNews } from '../../api/news/get/index';
import { loadBlogInfos } from "../../api/tobit-blog/get";

export type BlogItem = {
    boardId: number;
    creationTime: string;
    id: number;
    images: [{
        id: number;
        url: string;
    }],
    text: string;
    title: string;
}

export type NewsItem = {
    createdTimestamp: number,
    description: string,
    facebookId: string,
    id: string,
    imageList: string,
    imageUrl: string,
    link: string,
    locationId: number,
    locationName: string,
    mashupLocationSourceId: number,
    message: string,
    modifiedTime: string,
    modifiedTimestamp: number,
    name: string,
    publishTime: string,
    publishTimestamp: number,
    siteId: string,
    type: string,
    creationTime: string,
    headline: string,
};

export type News = {
    hasMore: boolean,
    searchHasMore: boolean,
    searchValues: Array<NewsItem>,
    values: Array<NewsItem>,
    isLoadingNews: boolean,
};
let blogEntries;
export const handleLoadNews = createAsyncThunk(
    'news/handleLoadNews',
    async ({ sites = [], date = new Date().toISOString() }: { sites: Array<Location>, date?: string }) => {
        const filterSites = sites.filter((site: Location, i: number, arr: Array<Location>) => !site.isHidden && arr.findIndex((site2) => (site.siteId === site2.siteId)) === i);
        const sitesIds = filterSites.map((location) => (location.siteId));
        const { status, result } = await loadNews(sitesIds.slice(0, 50), date, null);
        const { status: blogStatus, result: blogResult } = blogEntries || await loadBlogInfos();

        blogEntries = {status: blogStatus, result: blogResult};
        let items = [];
        if (status === 200 && result) {
            items = result;
        }
        const domParser = new DOMParser();
        const htmlToText = (html) => domParser.parseFromString(html, 'text/html').documentElement.textContent;

        if (blogStatus === 200 && blogResult) {
            items = blogResult.map(x => ({
                headline: htmlToText(x.title),
                publishTime: x.creationTime,
                message: htmlToText(x.text).slice(0, 100),
                locationId: 1214,
                id: x.id,
                siteId: '60038-22141',
                blogEntry: true,
                tappId: 677975,
                postId: x.id
            })).concat(items);
        }

        return {
            news: items,
        };
    },
);
