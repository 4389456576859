import { createAction } from '@reduxjs/toolkit';

import { SET_FLOATING_BUTTON, SET_IMAGE_WRAPPER, SET_VIDEO_WRAPPER, SET_WAIT_CURSOR } from './types';

export type FloatingButtonType = {
    callback: () => void,
    enabled: boolean,
    color: string,
    icon: string,
    text: string,
    id: number,
};

export type WaitCursor = {
    enabled?: boolean,
    text?: string,
    timeout?: number,
    progress?: number,
    progressText?: string,
};

export type ImageWrapper = {
    urls?: Array<string>,
    startIndex?: number
};

export type VideoWrapper = {
    url?: string,
    startIndex?: number
};

export type Components = {
    floatingButton: Array<FloatingButtonType>,
    waitCursor: WaitCursor,
    imageWrapper: ImageWrapper,
    videoWrapper: VideoWrapper,
};

export const setFloatingButton = createAction<Array<FloatingButtonType>>(SET_FLOATING_BUTTON);
export const setWaitCursor = createAction<WaitCursor>(SET_WAIT_CURSOR);
export const setImageWrapper = createAction<ImageWrapper>(SET_IMAGE_WRAPPER);
export const setVideoWrapper = createAction<VideoWrapper>(SET_VIDEO_WRAPPER);
