import { SITES_URL } from '../../../constants/defaults';

import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const deleteSite = async (id: number): Promise<{ status: number }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'DELETE ignore site',
        };

        const requestInit = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${typeof chaynsInfo !== 'undefined' ? chaynsInfo.User.TobitAccessToken : chayns.env.user.tobitAccessToken}`,
            },
        };

        const response = await logFetch(`${SITES_URL}Items/${id}`, requestInit, logOptions);

        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to delete site',
            section: 'api/sites/delete/deleteSite.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { deleteSite };
