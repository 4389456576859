import { combineReducers } from 'redux';

import envReducer from './env/reducer';
import appReducer from './app/reducer';
import userReducer from './user/reducer';
import tappsReducer from './tapps/reducer';
import contextMenuReducer from './context-menu/reducer';
import textStringReducer from './text-strings/reducer';
import dialogReducer from './dialog/reducer';
import sitesReducer from './sites/reducer';
import componentsReducer from './components/reducer';
import smartClientReducer from './smart-client/reducer';
import newsReducer from './news/reducer';
import intercomReducer from './intercom/reducer';
import searchReducer from './search/reducer';

const rootReducer = combineReducers({
    env: envReducer,
    app: appReducer,
    sitesObject: sitesReducer,
    user: userReducer,
    textStrings: textStringReducer,
    tapps: tappsReducer,
    contextMenu: contextMenuReducer,
    dialog: dialogReducer,
    components: componentsReducer,
    smartClient: smartClientReducer,
    news: newsReducer,
    intercom: intercomReducer,
    search: searchReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
