import { createAction } from '@reduxjs/toolkit';

import { SET_DIALOG } from './types';

export type Dialog = {
    id?: string,
    isList?: boolean,
    contextType?: string,
    x?: number,
    y?: number,
    dialogType?: string,
    data?: unknown,
    type?: string,
};

export const setDialog = createAction<Dialog>(SET_DIALOG);
