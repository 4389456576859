import { Folder } from '../../../types/folder';
import { EmailConnection, Mail, SmartClientUser } from '../../../types/smartClient';
import { DETAILED_MAIL, MAILS, UNREAD_ITEM_COUNT, UPDATED_MAILS, USERS, USER_ME } from '../graph/queryHelper';
import { requestGraph } from './graphClient';

const loadData = async (
    endpoint: string,
    filter: string,
): Promise<{
        status: number,
        result?: {
            data: {
                me: SmartClientUser,
                emailConnection: EmailConnection,
                users: Array<SmartClientUser>,
            }
        }
}> => requestGraph(
    endpoint,
    [
        USERS.query(),
        USER_ME.query(),
        MAILS.query({
            take: 30,
            filter,
        }),
    ],
);

const updateMails = async (
    endpoint: string,
    lastFetch: string,
    token: string,
): Promise<{
        status: number,
        result?: {
            data: {
                emailConnection: EmailConnection,
                folder: Folder
            }
        }
    }> => requestGraph(
    endpoint,
    [
        UNREAD_ITEM_COUNT.query(),
        UPDATED_MAILS.query({
            lastFetch,
        }),
    ],
    token,
);

const loadMailData = async (
    endpoint: string,
    id: string,
): Promise<{
        status: number,
        result?: {
            data: {
                email: Mail,
            }
        }
    }> => requestGraph(
    endpoint,
    [
        DETAILED_MAIL.query({
            mailId: id,
        }),
    ],
);

const loadMoreMails = async (
    endpoint: string,
    skipToken: string,
    filter: string,
    token: string,
): Promise<{
    status: number,
    result?: {
        data: {
            emailConnection: EmailConnection,
        }
    }
}> => requestGraph(
    endpoint,
    [
        MAILS.query({
            skipToken,
            filter,
        }),
    ],
    token,
);

const loadSearchMails = async (
    endpoint: string,
    searchString: string,
):
    Promise<{
        status: number,
        result?: {
            data: {
                emailConnection: EmailConnection,
            }
        }
    }> => {
    const filter = `(contains(subject, ${searchString}) or contains(from.name, ${searchString}) or contains(from.email, ${searchString})) and isRead eq 0`;
    return requestGraph(
        endpoint,
        [
            MAILS.query({
                filter,
                take: 2,
            }),
        ],
    );
};

const loadMoreSearchMails = async (
    endpoint: string,
    searchString: string,
    skipToken: string,
    token: string,
): Promise<{
    status: number,
    result?: {
        data: {
            emailConnection: EmailConnection,
        }
    }
}> => {
    const filter = `(contains(subject, ${searchString}) or contains(from.name, ${searchString}) or contains(from.email, ${searchString})) and isRead eq 0`;
    return requestGraph(
        endpoint,
        [
            MAILS.query({
                filter,
                skipToken,
            }),
        ],
        token,
    );
};

export {
    loadData,
    updateMails,
    loadMailData,
    loadMoreMails,
    loadSearchMails,
    loadMoreSearchMails
};
