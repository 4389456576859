/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */
import '../utils/style/index.scss';

import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import { useStore } from '../redux-modules/store';

// eslint-disable-next-line react/function-component-definition
export default function App({ Component, pageProps }: AppProps): JSX.Element {
    const store = useStore(pageProps.initialReduxState);

    return (
        <Provider store={store}>
            <Component {...pageProps}/>
        </Provider>
    );
}
