import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { Dialog, setDialog } from './actions';

const initialState : Dialog = {};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setDialog, (draft, action) => action.payload);

    builder.addCase(resetState, () => initialState);
});

export default reducer;
