export const SET_SEARCH_STRING = 'SET_SEARCH_STRING';

export const SET_IS_SEARCHING_SITES = 'SET_IS_SEARCHING_SITES';
export const SET_SEARCH_SITES = 'SET_SEARCH_SITES';
export const SET_SITES_PAGE = 'SET_SITES_PAGE';
export const SET_SITES_ACTIVE_PAGE = 'SET_SITES_ACTIVE_PAGE';

export const SET_IS_SEARCHING_PAGES = 'SET_IS_SEARCHING_PAGES';
export const SET_SEARCH_PAGES = 'SET_SEARCH_PAGES';
export const SET_PAGES_PAGE = 'SET_PAGES_PAGE';
export const SET_PAGES_ACTIVE_PAGE = 'SET_PAGES_ACTIVE_PAGE';

export const SET_IS_SEARCHING_PERSONS = 'SET_IS_SEARCHING_PERSONS';
export const SET_SEARCH_PERSONS = 'SET_SEARCH_PERSONS';
export const SET_PERSONS_PAGE = 'SET_PERSONS_PAGE';
export const SET_PERSONS_ACTIVE_PAGE = 'SET_PERSONS_ACTIVE_PAGE';

export const CLEAR_SEARCH = 'CLEAR_SEARCH';
