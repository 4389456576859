import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { Search, setClearSearch, setIsSearchingPages, setIsSearchingPersons, setIsSearchingSites, setPagesActivePage, setPagesPageResult, setPersonsActivePage, setPersonsPageResult, setSearchPages, setSearchPersons, setSearchSites, setSearchString, setSitesActivePage, setSitesPageResult } from './actions';

const initialState : Search = {
    searchString: '',
    sitesSearch: {},
    pagesSearch: {},
    personsSearch: {},
    isSearchingPages: false,
    isSearchingPersons: false,
    isSearchingSites: false,
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setSearchString, (draft, action) => {
        draft.searchString = action.payload;
    });

    // Sites
    builder.addCase(setIsSearchingSites, (draft, action) => {
        draft.isSearchingSites = action.payload;
    });

    builder.addCase(setSearchSites, (draft, action) => {
        const { value } = action.payload;
        draft.sitesSearch = {
            ...value,
            activePage: 1,
        };
        draft.isSearchingSites = false;
    });

    builder.addCase(setSitesPageResult, (draft, action) => {
        const { value } = action.payload;
        const {
            values,
            startIndex,
        } = value;

        if (!draft.sitesSearch?.values) {
            draft.sitesSearch.values = [];
        }
        for (let index = 0; index < values.length; index += 1) {
            const element = values[index];
            draft.sitesSearch.values[startIndex + index] = element;
        }
        draft.isSearchingSites = false;
    });

    builder.addCase(setSitesActivePage, (draft, action) => {
        draft.sitesSearch.activePage = action.payload;
    });

    // Persons
    builder.addCase(setIsSearchingPersons, (draft, action) => {
        draft.isSearchingPersons = action.payload;
    });

    builder.addCase(setSearchPersons, (draft, action) => {
        const { value } = action.payload;
        draft.personsSearch = {
            ...value,
            activePage: 1,
        };
        draft.isSearchingPersons = false;
    });

    builder.addCase(setPersonsPageResult, (draft, action) => {
        const { value } = action.payload;
        const {
            values,
            startIndex,
        } = value;

        if (!draft.personsSearch?.list) {
            draft.personsSearch.list = [];
        }
        for (let index = 0; index < values.length; index += 1) {
            const element = values[index];
            draft.personsSearch.list[startIndex + index] = element;
        }
        draft.isSearchingPersons = false;
    });

    builder.addCase(setPersonsActivePage, (draft, action) => {
        draft.personsSearch.activePage = action.payload;
    });

    // Pages
    builder.addCase(setIsSearchingPages, (draft, action) => {
        draft.isSearchingPages = action.payload;
    });

    builder.addCase(setSearchPages, (draft, action) => {
        const { value } = action.payload;

        draft.pagesSearch = {
            ...value,
            activePage: 1,
        };
        draft.isSearchingPages = false;
    });

    builder.addCase(setPagesPageResult, (draft, action) => {
        const { value } = action.payload;

        const {
            values,
            startIndex,
        } = value;

        if (!draft.pagesSearch?.values) {
            draft.pagesSearch.values = [];
        }
        for (let index = 0; index < values.length; index += 1) {
            const element = values[index];
            draft.pagesSearch.values[startIndex + index] = element;
        }
        draft.isSearchingPages = false;
    });

    builder.addCase(setPagesActivePage, (draft, action) => {
        draft.pagesSearch.activePage = action.payload;
    });

    builder.addCase(setClearSearch, (draft) => {
        draft.personsSearch = {};
        draft.pagesSearch = {};
        draft.sitesSearch = {};
    });

    builder.addCase(resetState, () => initialState);
});

export default reducer;
