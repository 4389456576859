const TAPPIDS = {
    INFO_CENTER: 393112,
    CHAYNS_ID: 552789,
    INTERCOM: 251441,
    WALLET: 250357,
    DOCUMENTS: 250358,
    MONEY: 250359,
    QUIZ: 456039,
    RESPONSIVE_ACCORDIONS: 398214,
    INSTALLATION: 413768,
    LANDING_PAGE: 445896,
    IMPRESSUM: 210771,
    SMART_CLIENT: 488430,
    LOGIN_TAPP_ID: 445204,
    SPACE: 599952,
    CALENDAR: 663696,
    DAVID: 10000000,
    LOGIN_COMPLETED: 10000001,
    SIDEKICK: 734886,
    APP_INFO_CENTER: 10000002,
    CHAYNS_SITES: -10000,
};

export default TAPPIDS;
