import { createReducer } from '@reduxjs/toolkit';
import { Tapp } from '../../types/tapp';
import { setTapps, updateTapp } from './actions';
import TAPPIDS from "../../constants/tapp-ids";
import { CALENDAR } from "../../constants/types";

const initialState = [] as Array<Tapp>;

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setTapps, (draft, action) => {
        let newTapps = JSON.parse(JSON.stringify(action.payload));

       // newTapps = newTapps.filter(x => x.tappId !== TAPPIDS.CALENDAR);

        const smartClientIndex = newTapps.findIndex(x => x.tappId === TAPPIDS.SMART_CLIENT);

        if(smartClientIndex !== -1) {
            const calendarSmartClientIndex = newTapps[smartClientIndex]?.children?.findIndex(x => x.identifier === 'calendar-staging') ??-1;
            if(calendarSmartClientIndex !== -1) {
                newTapps[smartClientIndex].children[calendarSmartClientIndex].identifier = CALENDAR;
            }
        }

        return newTapps
    });

    builder.addCase(updateTapp, (draft, action) => {
        const tappIndex = draft.findIndex((tapp) => tapp.tappId === action.payload.id);
        if (tappIndex > -1) {
            draft[tappIndex] = action.payload;
        }
    });
});

export default reducer;
