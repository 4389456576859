import mergeWith from 'lodash.mergewith';

const mergeArrays = (
    baseArray: Array<unknown>,
    mergeArray = [],
): Array<unknown> => {
    if (!baseArray) {
        return mergeArray;
    }

    const result = Array.from(baseArray);

    mergeArray.forEach((mergeItem) => {
        const itemIndex = result.findIndex((item: { id: number }) => item.id === mergeItem.id);

        if (itemIndex > -1) {
            const baseItem = result[itemIndex];

            if (baseItem) {
                // eslint-disable-next-line @typescript-eslint/no-use-before-define
                mergeThread(baseItem, mergeItem);
            }
        } else {
            result.push(mergeItem);
        }
    });

    return result;
};

const mergeThread = (thread: unknown, update: () => void): void => {
    mergeWith(thread, update, (objValue, srcValue, key) => {
        if (key === 'messages' || key === 'members') {
            return mergeArrays(objValue, srcValue);
        }

        if (key === 'lastMessage') {
            return srcValue;
        }

        return undefined;
    });
};

export {
    mergeArrays,
    mergeThread,
};
