import logger from "../constants/logger";

export const scrubString = (str: string): string => {
    const splittedToken = str.split('.');
    if (splittedToken && splittedToken[0] && splittedToken[1]) {
        return `${splittedToken[0]}.${splittedToken[1]}`;
    }
    return '';
};

type RequestInit = {
    headers: {
        authorization: string,
        Authorization: string,
    }
};

export const scrubAuthHeader = (requestInit: RequestInit): RequestInit => ({
    ...(requestInit && {
        ...requestInit,
        ...(requestInit.headers && {
            headers: {
                ...requestInit.headers,
                ...(requestInit.headers.authorization && {
                    authorization: scrubString(requestInit.headers.authorization),
                }),
                ...(requestInit.headers.Authorization && {
                    Authorization: scrubString(requestInit.headers.Authorization),
                }),
            },
        }),
    }),
});

const logFetch = async (url: string, requestObj: globalThis.RequestInit, { fileName, message }: { fileName: string, message: string }): Promise<Response> => {
    const response = await fetch(url, requestObj)
        .catch((ex) => {
            if (ex.name === 'AbortError') {
                return response;
            }
            logger().warning({
                message: `${message} request failed`,
                section: 'logFetch',
                data: {
                    requestUrl: url,
                    fileName,
                    auth: scrubAuthHeader(requestObj as RequestInit),
                },
            }, {
                message: ex?.message,
            });
            return undefined;
        });

    if (!response?.status) {
        return response;
    }

    const { status } = response;

    if (status === 401 || status === 429 || status === 500 || status === 403) {
        logger().warning({
            message: `${message} error statuscode received`,
            section: 'logFetch',
            data: {
                requestUrl: url,
                status,
                fileName,
                auth: scrubAuthHeader(requestObj as RequestInit),
            },
        });
    } else if (status >= 400) {
        logger().error({
            message: `${message} error statuscode received`,
            section: 'logFetch',
            data: {
                requestUrl: url,
                status,
                fileName,
                auth: scrubAuthHeader(requestObj as RequestInit),
            },
        });
    }

    return response;
};

export default logFetch;
