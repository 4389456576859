const env = process.env.NEXT_PUBLIC_BUILD_ENV ?? 'development';

const isPRODUCTION = env === 'production';
const isSTAGING = env === 'staging';
const isDEVELOPMENT = env === 'development';
const isQA = env === 'qa';

module.exports = {
    isPRODUCTION,
    isSTAGING,
    isDEVELOPMENT,
    isQA,
};
