import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { ContextMenu, setContextMenu } from './actions';

const initialState: ContextMenu = {};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setContextMenu, (draft, action) => action.payload);

    builder.addCase(resetState, () => initialState);
});

export default reducer;
