export const SET_APP_STATE = 'SET_APP_STATE';
export const SET_IS_CLIENT = 'SET_IS_CLIENT';
export const SET_IS_CHAYNS_READY = 'SET_IS_CHAYNS_READY';
export const SET_IS_SCANNER_SHOWN = 'SET_IS_SCANNER_SHOWN';
export const SET_ACTIVE_DAVID_SITE_ID = 'SET_ACTIVE_DAVID_SITE_ID';
export const SET_IS_MENU_SHOWN = 'SET_IS_MENU_SHOWN';
export const SET_ACTIVE_TAPP = 'SET_ACTIVE_TAPP';
export const SET_IS_USER_AUTHENTICATED = 'SET_IS_USER_AUTHENTICATED';
export const SET_IS_SMART_CLIENT_AVAILABLE = 'SET_IS_SMART_CLIENT_AVAILABLE';
export const SET_BOUNDINGS = 'SET_BOUNDINGS';
export const SET_IS_TAPPS_SCROLLED = 'SET_IS_TAPPS_SCROLLED';
export const SET_IS_CHAYNS_ICON_IN_HEADER = 'SET_IS_CHAYNS_ICON_IN_HEADER';
export const SET_SELECTED_CONTENT_TYPE = 'SET_SELECTED_CONTENT_TYPE';
export const SET_SELECTED_ITEM = 'SET_SELECTED_ITEM';
export const SET_COLOR_MODE = 'SET_COLOR_MODE';
export const SET_IS_SEARCHING_MESSAGES = 'SET_IS_SEARCHING_MESSAGES';
export const SET_MESSAGE_SEARCH_STRING = 'SET_MESSAGE_SEARCH_STRING';
export const SET_DAVID_DIALOG_COLOR = 'SET_DAVID_DIALOG_COLOR';
export const SET_GEO_COORDINATES = 'SET_GEO_COORDINATES';
export const SET_GEO_POSITION_AVAILABLE = 'SET_GEO_POSITION_AVAILABLE';
export const RESET_STATE = 'RESET_STATE';
export const SET_ACTIVE_SMART_CLIENT_TAPP = 'SET_ACTIVE_SMART_CLIENT_TAPP';
export const SET_LEFT_BAR_ACTIVE = 'SET_LEFT_BAR_ACTIVE';
export const SET_IFRAME_POINTER_EVENTS = 'SET_IFRAME_POINTER_EVENTS';
export const SET_LOGIN_OVERLAY = 'SET_LOGIN_OVERLAY';
export const LOAD_CALENDAR_STORE = 'LOAD_CALENDAR_STORE';
export const LOAD_SMARTCLIENT_STORE = 'LOAD_SMARTCLIENT_STORE';
export const LOAD_INTERCOM_WEBSOCKET = 'LOAD_INTERCOM_WEBSOCKET';
export const SET_TOOLBAR_HEIGHT = 'SET_TOOLBAR_HEIGHT';
export const SET_HOT_CARD_HEIGHT = 'SET_HOTCARD_HEIGHT';
export const CLEAR_VR_LOGIN = 'CLEAR_VR_LOGIN';
export const SET_SHOW_TRANSITIONING_OVERLAY = 'SET_SHOW_TRANSITIONING_OVERLAY';
export const SET_IS_CHAYNS_JS_LOADED = 'SET_IS_CHAYNS_JS_LOADED';
