import { createReducer } from '@reduxjs/toolkit';
import { User } from '../../types/user';
import { setUser } from './actions';

const initialState = {} as User;

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setUser, (draft, action) => ({ ...draft, ...action.payload }));
});

export default reducer;
