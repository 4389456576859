import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { handleLoadNews, News, NewsItem } from './actions';

const initialState : News = {
    hasMore: false,
    searchHasMore: false,
    searchValues: [],
    values: [],
    isLoadingNews: true,
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(handleLoadNews.fulfilled, (draft, action) => {
        const {
            news,
        } = action.payload;
        draft.isLoadingNews = false;
        draft.hasMore = news.length >= 20;
        draft.values = [...draft.values, ...news];
        draft.values = draft.values.filter((news1: NewsItem, index: number, array: Array<NewsItem>) => array.findIndex((news2) => (news2.id === news1.id)) === index);
        draft.values.sort((a: NewsItem, b: NewsItem) => {
            const timeA = a.publishTime || a.creationTime;
            const timeB = b.publishTime || b.creationTime;
            return timeB.localeCompare(timeA);
        });
    });

    builder.addCase(resetState, () => initialState);
});

export default reducer;
