import { SITES_URL } from '../../../constants/defaults';
import { Sites } from '../../../redux-modules/sites/actions';

import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const loadSites = async (isMyChaynsApp: boolean): Promise<{ status: number, result?: Sites}> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET sites',
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${(typeof chaynsInfo !== 'undefined' && chaynsInfo.User?.TobitAccessToken) || (typeof chayns !== 'undefined' && chayns.env.user.tobitAccessToken)}`,
            },
        };

        const response = await logFetch(`${SITES_URL}Items${!isMyChaynsApp ? '?includeHidden=true' : ''}`, requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load sites',
            section: 'api/sites/get/index.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadSites };
