import { createReducer } from '@reduxjs/toolkit';
import { resetState } from '../app/actions';
import { setFloatingButton, Components, setWaitCursor, setImageWrapper, setVideoWrapper } from './actions';

const initialState: Components = {
    floatingButton: [],
    waitCursor: {},
    imageWrapper: {},
    videoWrapper: {},
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setFloatingButton, (draft, action) => {
        draft.floatingButton = action.payload;
    });

    builder.addCase(setWaitCursor, (draft, action) => {
        draft.waitCursor = action.payload;
    });

    builder.addCase(setImageWrapper, (draft, action) => {
        draft.imageWrapper = action.payload;
    });

    builder.addCase(setVideoWrapper, (draft, action) => {
        draft.videoWrapper = action.payload;
    });
    builder.addCase(resetState, () => initialState);
});

export default reducer;
