import { createReducer } from '@reduxjs/toolkit';
import { INBOX, SITES } from '../../constants/types';
import {
    App, clearVrLogin, loadCalendarStore, loadIntercomWebsocket, loadSmartClientStore,
    resetState,
    setActiveDavidSiteId,
    setActiveSmartClientTapp,
    setActiveTapp,
    setAppState,
    setBoundings,
    setColorMode,
    setDavidDialogColor, setHotCardHeight, setIFramePointerEvents,
    setIsChaynsIconInHeader, setIsChaynsJsLoaded,
    setIsChaynsReady,
    setIsClient,
    setIsMenuShown,
    setIsScannerShown,
    setIsSearchingMessages,
    setIsSmartClientAvailable,
    setIsTappScrolled,
    setIsUserAuthenticated,
    setLeftBarActive, setLoginOverlay,
    setMessageSearchString,
    setSelectedContentType,
    setSelectedItem, setShowTransitioningOverlay, setToolbarHeight,
} from './actions';

const initialState : App = {
    width: 0,
    height: 0,
    activeTapp: 0,
    color: null,
    colorMode: 'light',
    davidDialogTitleColor: null,
    isUserAuthenticated: false,
    isSmartClientAvailable: false,
    isChaynsReady: false,
    isClient: false,
    isScannerShown: false,
    activeDavidSiteId: null,
    allowStagingSwitch: false,
    isMenuShown: false,
    sitesBackgroundColor: null,
    isTappScrolled: false,
    hideOtherContent: false,
    isChaynsIconInHeader: false,
    selectedContentType: SITES,
    isSearchingMessages: false,
    messageSearchString: '',
    activeSmartClientTapp: INBOX,
    siteTapps: [],
    leftBarActive: false,
    loadCalendarStore: false,
    loadIntercomWebsocket: false,
    toolbarHeight: 0,
    hotCardHeight: 0,
    showTransitioningOverlay: false,
    isChaynsJsLoaded: false,
};

const reducer = createReducer(initialState, (builder) => {
    builder.addCase(setAppState, (draft: App, action) => action.payload);

    builder.addCase(setIsClient, (draft: App, action) => {
        draft.isClient = action.payload;
    });

    builder.addCase(setIsChaynsReady, (draft: App, action) => {
        draft.isChaynsReady = action.payload;
    });

    builder.addCase(setIsScannerShown, (draft: App, action) => {
        draft.isScannerShown = action.payload;
    });

    builder.addCase(setActiveDavidSiteId, (draft: App, action) => {
        draft.activeDavidSiteId = action.payload;
    });

    builder.addCase(setIsMenuShown, (draft: App, action) => {
        draft.isMenuShown = action.payload;
    });

    builder.addCase(setActiveTapp, (draft: App, action) => {
        draft.activeTapp = action.payload;
    });

    builder.addCase(setIsUserAuthenticated, (draft: App, action) => {
        draft.isUserAuthenticated = action.payload;
    });

    builder.addCase(setBoundings, (draft: App, action) => {
        draft.width = action.payload.width;
        draft.height = action.payload.height;
    });

    builder.addCase(setIsTappScrolled, (draft: App, action) => {
        draft.isTappScrolled = action.payload;
    });

    builder.addCase(setIsChaynsIconInHeader, (draft: App, action) => {
        draft.isChaynsIconInHeader = action.payload;
    });

    builder.addCase(setSelectedContentType, (draft: App, action) => {
        draft.selectedContentType = action.payload;
    });

    builder.addCase(setSelectedItem, (draft: App, action) => {
        draft.selectedItem = action.payload;
    });

    builder.addCase(setColorMode, (draft: App, action) => {
        draft.colorMode = action.payload;
    });

    builder.addCase(setShowTransitioningOverlay, (draft: App, action) => {
        draft.showTransitioningOverlay = action.payload;
    });

    builder.addCase(setIsSearchingMessages, (draft: App, action) => {
        draft.isSearchingMessages = action.payload;
    });

    builder.addCase(setMessageSearchString, (draft: App, action) => {
        draft.messageSearchString = action.payload;
    });

    builder.addCase(setIsSmartClientAvailable, (draft: App, action) => {
        draft.isSmartClientAvailable = action.payload;
    });

    builder.addCase(setDavidDialogColor, (draft: App, action) => {
        draft.davidDialogTitleColor = action.payload;
    });

    builder.addCase(setActiveSmartClientTapp, (draft: App, action) => {
        draft.activeSmartClientTapp = action.payload;
    });

    builder.addCase(setLoginOverlay, (draft: App, action) => {
        if (typeof action.payload === 'boolean') {
            if (action.payload) {
                draft.loginOverlay = {
                    tokenType: 12,
                };
            } else {
                draft.loginOverlay = undefined;
            }
        } else {
            draft.loginOverlay = action.payload;
        }
    });

    builder.addCase(setLeftBarActive, ((draft, action) => {
        draft.leftBarActive = action.payload;
    }));

    builder.addCase(setIFramePointerEvents, ((draft, action) => {
        draft.iframePointerEvents = action.payload;
    }));

    builder.addCase(resetState, (draft) => ({
        ...draft,
        isSmartClientAvailable: false,
        isScannerShown: false,
        activeDavidSiteId: null,
        isMenuShown: false,
        isTappScrolled: false,
        isChaynsIconInHeader: false,
        selectedContentType: SITES,
        isSearchingMessages: false,
        messageSearchString: '',
        selectedItem: null,
    }));

    builder.addCase(loadCalendarStore, (draft, action) => {
        draft.loadCalendarStore = action.payload;
    });

    builder.addCase(loadSmartClientStore, (draft, action) => {
        draft.loadSmartClientStore = action.payload;
    });
    builder.addCase(loadIntercomWebsocket, (draft, action) => {
        draft.loadIntercomWebsocket = action.payload;
    });
    builder.addCase(setToolbarHeight, (draft, action) => {
        draft.toolbarHeight = action.payload;
    });
    builder.addCase(setHotCardHeight, (draft, action) => {
        draft.hotCardHeight = action.payload;
    });
    builder.addCase(clearVrLogin, (draft, action) => {
        draft.isVrLogin = false;
    });

    builder.addCase(setIsChaynsJsLoaded, (draft) => {
        draft.isChaynsJsLoaded = true;
    });
});

export default reducer;
