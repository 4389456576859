import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const requestGraph = async <T> (endpoint: string, queryList: Array<string>, accessToken = null): Promise<{ status: number, result?: T }> => {
    const logOptions = {
        fileName: __filename,
        section: '0',
        message: 'GET Mail',
    };
    const requestInit = {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${accessToken || (typeof chaynsInfo !== 'undefined' && chaynsInfo.User?.TobitAccessToken) || (typeof chayns !== 'undefined' && chayns.env.user.tobitAccessToken)}`,
            'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify({
            query: `{${queryList.reduce((query, current) => query.concat(current), '')}}`,
        }),
    };

    try {
        const response = await logFetch(`${endpoint}v1.0/graph`, requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'failed graph request',
            section: 'api/mail/post/graph',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { requestGraph };
