import { Member } from '../../../../../../../types/thread';

export const getIsLocationIntercom = (): boolean => false;

export const getMyMember = (members: Member[], userId: number): Member => members.find((member) => (member.tobitId === userId));

export const getActiveMembers = (members: Member[]): Member[] => members.filter((member) => !member.isDeleted && !member.leaveTime);

export const getOtherMembers = (members: Member[], userId: number): Member[] => members.filter((member) => (getIsLocationIntercom() ? member.locationId !== 378 : member.tobitId !== userId));

interface GetMemberNameOptions {
    shouldGetFullName?: boolean;
}

export const getMemberName = (
    member: Member,
    { shouldGetFullName }: GetMemberNameOptions = {},
): string => {
    const memberName = (
        member.name
        || `${member.firstName || ''} ${(shouldGetFullName ? member.lastName : '') || ''}`
    ).trim();

    if (memberName !== '') {
        if (member.reference) {
            return [memberName, member.reference.name].join(' @');
        }
        return memberName;
    }

    if (typeof member.locationId === 'number') {
        return 'unbekannte Site';
        // return getTextstringValue(textstrings.memberNames.unknownSite);
    }
    return 'unbekannte Person';
    // return getTextstringValue(textstrings.memberNames.unknownPerson);
};

interface GetMemberNamesOptions {
    maxShownMembers?: number;
    myMember?: Member;
    shouldAddLastNameSeparated?: boolean;
    shouldForceAddMyMember?: boolean;
    shouldUseAccusative?: boolean;
    shouldGetFullNames?: boolean;
}

export const getMemberNames = (
    members: Member[],
    {
        maxShownMembers = 0,
        myMember,
        shouldAddLastNameSeparated = false,
        shouldForceAddMyMember = false,
        shouldGetFullNames = false,
    }: GetMemberNamesOptions = {},
): string => {
    let memberNames = '';

    const shownMembers = members;

    if ((shownMembers.length === 0 || shouldForceAddMyMember) && myMember) {
        shownMembers.push(myMember);
    }

    const shownMemberCount = shownMembers.length;
    const iterationLength = typeof maxShownMembers === 'number'
        && maxShownMembers > 0
        && maxShownMembers < shownMemberCount
        ? maxShownMembers
        : shownMemberCount;

    for (let i = 0; i < iterationLength; i++) {
        if (shouldAddLastNameSeparated && i === shownMemberCount - 1 && i > 0) {
            // memberNames += getTextstringValue(textstrings.memberNames.separator);
            memberNames += ',';
        } else if (i > 0) {
            memberNames += ', ';
        }

        memberNames += getMemberName(shownMembers[i], { shouldGetFullName: shouldGetFullNames });
    }

    if (iterationLength < shownMemberCount) {
        const moreCount = shownMemberCount - iterationLength;

        if (moreCount > 1) {
            // memberNames += getTextstringValue(textstrings.memberNames.andMore, replacements);
            memberNames += 'und mehr';
        } else {
            memberNames += 'und einer';
            // memberNames += getTextstringValue(textstrings.memberNames.andOneMore);
        }
    }

    return memberNames;
};

export const getMembersImages = (members: Member[]): string[] => {
    const userUrls = members
        .filter(({ tobitId }) => typeof tobitId === 'number')
        .map(({ tobitId }) => `https://sub60.tobit.com/u/${tobitId}?size=90`);

    const locationUrls = members
        .filter(({ locationId }) => typeof locationId === 'number')
        .map(({ locationId }) => `https://sub60.tobit.com/l/${locationId}?size=90`);

    return getIsLocationIntercom()
        ? [...userUrls, ...locationUrls]
        : [...locationUrls, ...userUrls];
};

export const getMemberById = (members: Member[], id: string): Member | undefined => members.find((member) => member.id === id);

export const getIsExactOneLocationGiven = (members: Member[]): boolean => members.filter(({ locationId }) => typeof locationId === 'number').length === 1;
