import { NEWS_URL } from '../../../constants/defaults';
import { NewsItem } from '../../../redux-modules/news/actions';

import logFetch from '../../../utils/logFetch';
import logger from "../../../constants/logger";

const loadNews = async (siteIds: Array<string>, date: string, searchString: string): Promise<{ status: number, result?: Array<NewsItem> }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET news',
        };

        const getToken = () => {
            if (typeof chaynsInfo !== 'undefined') return window.chaynsInfo.User.TobitAccessToken;
            return chayns.env.user.tobitAccessToken;
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${getToken()}`,
            },
        };

        const searchParams = searchString ? `&siteName=${searchString}&title=${searchString}&message=${searchString}` : '';

        const url = new URL(`${NEWS_URL}newsvstring?date=${date}&count=30${searchParams}`);

        if (siteIds?.length > 0) {
            siteIds.forEach((siteId) => {
                url.searchParams.append('siteIds', siteId);
            });
        }

        const response = await logFetch(url.toString(), requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load news',
            section: 'api/news/get/index.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadNews };
