import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import cloneDeep from 'lodash.clonedeep';
import TAPPIDS from '../../constants/tapp-ids';
import { Tapp } from '../../types/tapp';
import { User } from '../../types/user';
import { getTappsState } from './selector';

import { SET_TAPPS, UPDATE_TAPP } from './types';

export const setTapps = createAction<Array<Tapp>>(SET_TAPPS);
export const updateTapp = createAction<Tapp>(UPDATE_TAPP);

type TappResult = {
    tappId: number,
    text: {
        value: string,
    }
};

export const fetchBadges = createAsyncThunk(
    'tapps/fetchBadges',
    async ({ user }: {user: User, tapps: Array<Tapp>}, thunkApi) => {
        if (!user?.token) {
            return;
        }

        fetch('https://chaynssvc.tobit.com/v0.5/378/tappsuffix', {
            method: 'GET',
            headers: {
                authorization: `bearer ${user?.token}`,
            },
        }).then((res) => res.json()).then((json: { data : Array<TappResult>}) => {
            const tapps = getTappsState(thunkApi.getState());
            const copyTapps = cloneDeep(tapps);
            if (json && json.data) {
                json.data.forEach((t) => {
                    const tappIndex = tapps.findIndex(((obj) => obj.tappId === t.tappId));
                    if (t.tappId !== TAPPIDS.SPACE && t.tappId !== TAPPIDS.CALENDAR && tappIndex > -1) {
                        copyTapps[tappIndex].badge = parseInt(t.text.value, 10);
                    }
                });
            }

            if (tapps) {
                tapps.forEach((t, i) => {
                    let suffixIndex = -1;

                    if (json.data) {
                        suffixIndex = json.data.findIndex((suffix) => suffix.tappId === t.tappId);
                    }
                    if (suffixIndex === -1 && t.tappId !== TAPPIDS.SMART_CLIENT && t.tappId !== TAPPIDS.CALENDAR) {
                        copyTapps[i].badge = 0;
                    }
                });
            }
            thunkApi.dispatch(setTapps(copyTapps));
        });
    },
);
