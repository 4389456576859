/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-var-requires */
import { useMemo } from 'react';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, applyMiddleware, Store } from 'redux';
import thunk from 'redux-thunk';
import { isPRODUCTION } from '../constants/environments';
import rootReducer from './rootReducer';

export let store;

function initStore(initialState) {
    let middlewares = applyMiddleware(thunk);

    if (!isPRODUCTION) {
        middlewares = composeWithDevTools(middlewares);
    }
    return createStore(rootReducer, initialState, middlewares);
}

export const initializeStore = (preloadedState?: unknown): any => {
    const myStore = store ?? initStore(preloadedState);

    if (typeof window === 'undefined') return myStore;
    if (!store) store = myStore;

    return myStore;
};

export const useStore = (initialState: unknown): Store => {
    const memorizedStore = useMemo(() => initializeStore(initialState), [initialState]);
    return memorizedStore;
};

