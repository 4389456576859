import { BlogItem } from "../../redux-modules/news/actions";
import logFetch from "../../utils/logFetch";
import logger from "../../constants/logger";

const loadBlogInfos = async (): Promise<{ status: number, result?: Array<BlogItem> }> => {
    try {
        const logOptions = {
            fileName: __filename,
            section: '0',
            message: 'GET news',
        };

        const getToken = () => {
            if (typeof chaynsInfo !== 'undefined') return window.chaynsInfo.User.TobitAccessToken;
            return chayns.env.user.tobitAccessToken;
        };

        const requestInit = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${getToken()}`,
            },
        };

        const url = 'https://cube.tobit.cloud/blog/v1.0/sites/60038-22141/pages/677975/posts?order=desc&skip=0&top=10';

        const response = await logFetch(url, requestInit, logOptions);

        if (response.status === 200) {
            const result = await response.json();

            return {
                status: response.status,
                result,
            };
        }
        return {
            status: response.status,
        };
    } catch (ex) {
        logger().warning({
            message: 'Failed to load blog items',
            section: 'api/blog/get/get.js',
        }, {
            message: ex?.message,
        });
        return {
            status: 500,
        };
    }
};

export { loadBlogInfos }
