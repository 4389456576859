const UNREAD_ITEM_COUNT = {
    type: 'UNREAD_ITEM_COUNT',
    query: (): string => 'folder: folder(id: "inbox"){unreadItemCount}',
};

const getTake = ({
    skipToken,
    take,
    searchString,
}: {
    skipToken?: string,
    take?: number,
    searchString?: string
}) => {
    if (skipToken || searchString) {
        return 200;
    }
    if (take && take > 5) {
        return take + 5;
    }
    return 30;
};

const getFilter = ({
    filter,
    lastFetch,
}: { filter?: string, lastFetch?: string }): string => {
    if (filter) {
        return `filter: "${filter}"`;
    }
    if (lastFetch) {
        return `filter: "${`lastModifiedDateTime gt ${new Date(lastFetch).toJSON()} and isDeleted ne 4`}"`;
    }
    return '';
};

const UPDATED_MAILS = {
    type: 'UPDATED_MAILS',
    query: ({
        alias = 'emailConnection',
        lastFetch,
    }: {
        alias?: string,
        lastFetch?: string,
    }): string => `${alias}: emailConnection(
    orderBy: "createdDateTime desc"
    folderId: "inbox"
    take: 20
    ${getFilter({
        lastFetch,
    })}
  ) {
    values {
      subject
      hasComments
      from {
        id
        name
        email
      }
      isRead
      id
      isCc
      createdDateTime
      importance
      receivedDateTime
      isDeleted
      properties {
        isReminder
        isAnswered
        isRedirected
        isSigned
        isEncrypted
      }
      isMuted
      hasAttachments
      type
      previewImageUrl
    }
  }`,
};

/**
 * @type {{query: (function({alias?: *, lastFetch?: *, skipToken?: *, filter: *, take?: *}): string), type: string}}
 */
const MAILS = {
    type: 'MAILS',
    query: ({
        alias = 'emailConnection',
        lastFetch,
        skipToken,
        filter,
        take,
    }: {
        alias?: string,
        lastFetch?: string,
        skipToken?: string,
        filter?: string,
        take?: number,
    }): string => `${alias}: emailConnection(
    orderBy: "createdDateTime desc"
    folderId: "inbox"
    take: ${getTake({
        skipToken,
        take,
    })}
    skipToken: "${skipToken || ''}"
    ${getFilter({
        filter,
        lastFetch,
    })}
  ) {
    skipToken
    ${!skipToken && !lastFetch ? 'count' : ''}
    values {
      subject
      hasComments
      from {
        id
        name
        email
      }
      isRead
      id
      isCc
      createdDateTime
      importance
      receivedDateTime
      isDeleted
      properties {
        isReminder
        isAnswered
        isRedirected
        isSigned
        isEncrypted
      }
      isMuted
      hasAttachments
      type
      previewImageUrl
    }
  }`,
};

/**
 * @type {{query: (function({alias?: *, mailId: *}): string), type: string}}
 */
const DETAILED_MAIL = {
    type: 'DETAILED_MAIL',
    query: ({
        alias = 'email',
        mailId
    }): string => `${alias}: email(id: "${mailId}", replaceUnsafeImageLinks: true) {
    id
    type
    receivedDateTime
    body {
      content
      contentType
      referenceId
    }
    isMuted
    allowComments
    previewImageUrl
    createdDateTime
    toAddresses {
      email
      id
      name
    }
    ccAddresses {
      email
      id
      name
    }
    attachments {
      contentId
      contentType
      fetchUrl
      fileName
      id
      isInline
      size
      linkExpiration
    }
    comments {
      addresses {
        email
        id
        name
      }
      author {
        email
        id
        name
      }
      commentType
      creationTime
      id
      message
    }
  }`,
};

const USER_ME = {
    type: 'USER_ME',
    query: (): string => `me {
      id
      firstname
      lastname
      personId
      shorthand
  }`,
};

const USERS = {
    type: 'USERS',
    query: (): string => `users {
          id
          firstname
          lastname
          shorthand
          emails
      }`,
};

export {
    MAILS,
    UNREAD_ITEM_COUNT,
    DETAILED_MAIL,
    UPDATED_MAILS,
    USERS,
    USER_ME,
};
