import { Thread } from '../../../../../../../types/thread';
import { User } from '../../../../../../../types/user';
import { getMyMember } from './member';

const getIsThreadRead = (thread: Thread, user?: User): boolean => {
    const { members } = thread;
    let userId = user?.userId;
    if (!userId && typeof window !== 'undefined') {
        userId = typeof chaynsInfo !== 'undefined' ? window.chaynsInfo?.User?.ID : window?.chayns?.env?.user.id;
    }

    if (!userId) {
        return false;
    }

    const myMember = getMyMember(members, userId);
    if (!myMember) {
        return false;
    }

    const {
        unreadFlag,
        unreadCount,
        readTime,
    } = myMember;

    if (typeof unreadCount !== 'undefined' || typeof unreadFlag !== 'undefined') {
        return !unreadCount && !unreadFlag;
    }
    return readTime > thread.lastMessageTime;
};

export default getIsThreadRead;
